<template lang="pug">
  .agv-rules
    .table-of-contents
      li
        router-link(to="#what-do-we-offer")
          | Что предлагает {{renter.short_commercial_name_cases[0]}}?
      li
        router-link(to="#how-do-i-look-at-items")
          | Как посмотреть изделия?
      li
        router-link(to="#how-do-i-order")
          | Как заказать?
      li
        router-link(to="#how-do-i-pay")
          | Как оплатить?
      li
        router-link(to="#how-do-i-receive-and-return")
          | Как получить и вернуть изделия?
      li
        router-link(to="#who-does-the-setup")
          | Кто осуществляет сетап?
      li
        router-link(to="#can-i-cancel")
          | Можно ли отменить заказ?
      li
        router-link(to="#will-i-get-a-compensation")
          | Предусмотрены ли компенсации?
      li
        router-link(to="#what-is-the-price-of-rental")
          | Сколько стоит аренда?
      li
        router-link(to="#what-is-the-price-of-delivery")
          | Сколько стоит доставка?
      li
        router-link(to="#what-is-the-price-of-setup")
          | Сколько стоит сетап?
      li
        router-link(to="#how-do-i-save")
          | Как сэкономить?
      li
        router-link(to="#where-do-i-get-help")
          | Куда обратиться за помощью?
      li(v-if="renter.rules_addendum")
        router-link(to="#addendum")
          | Прочие условия
      li
        router-link(to="#details")
          | Реквизиты
    .questions
      .row
        .question
          .answer-container
            .answer
              p
                | Условия работы на этой странице — договор оферты, который действует для всех клиентов {{renter.short_commercial_name_cases[1]}}. Оплачивая заказ, вы соглашаетесь с этими условиями.
            .prompt
      .row
        .question
          h4#what-do-we-offer
            | Что предлагает {{renter.short_commercial_name_cases[0]}}?
          .answer-container
            .answer
              p
                | {{renter.short_commercial_name_cases[0]}} предлагает {{renter.rent_object_cases[3] | uncapitalize}} в аренду.
                |
                span(v-show='categories.length')
                  | В нашем ассортименте: {{listedCategories}}.
            .prompt
      .row
        .question
          h4#how-do-i-look-at-items
            | Как посмотреть изделия?
          .answer-container(v-if='branch.is_cp_demo_available')
            .answer
              p
                | Посмотреть изделия вживую можно в пункте выдачи {{renter.short_commercial_name_cases[1]}}
                | ({{branch.cp_address}}, {{branch.cp_off_days | cpWorkdaysFromOffDays}},
                |
                span(v-if="branch.freeform_cp_schedule")
                  | {{branch.freeform_cp_schedule | lineBreaksToCommas}}
                span(v-else)
                  | {{branch.cp_schedule | cpSchedule}}
                | ).
            .prompt
          .answer-container(v-else)
            .answer
              p
                | Вы можете заказать образцы изделий на&nbsp;{{renter.base_rent_duration | decline(['день','дня','дней'])}}&nbsp;по цене аренды.
                | Пробный заказ размещается обычным образом.
                |
                span(v-if="renter.smallest_rent_price_with_delivery === 0 && renter.smallest_rent_price_without_delivery === 0")
                  | Ограничения суммы минимального заказа у
                  | {{renter.short_commercial_name_cases[1]}}
                  | нет.
            .prompt
      .row
        .question
          h4#how-do-i-order
            | Как заказать?
          .answer-container
            .answer
              p
                | Разместите заказ на нашем сайте. Выберите изделия в
                |
                | каталоге
                | , укажите даты получения и возврата{{arrangementAndDeliveryString}}.
                | Внесите половину суммы для размещения заказа, оплатите остаток не позднее чем за
                | {{renter.freeze_duration + 1 | decline(['день','дня','дней'])}}
                | до получения изделий.
            .prompt
              p
                | Размещение заказа доступно не позднее, чем за
                | {{renter.freeze_duration + 1 | decline(['день','дня','дней'])}}
                | до  получения изделий.
      .row
        .question
          h4#how-do-i-pay
            | Как оплатить?
          .answer-container
            .answer
              p Внесите оплату {{allowedPaymentMethodsString}}.
            .prompt
      .row
        .question
          h4#how-do-i-receive-and-return
            | Как получить и вернуть изделия?
          .answer-container
            .answer
              p(v-if='!renter.is_delivery_offered')
                | Получите и верните изделия в определенные вами дни в пункте выдачи {{renter.short_commercial_name_cases[1]}}
                | ({{branch.cp_address}}, {{branch.cp_off_days | cpWorkdaysFromOffDays}}
                |
                span(v-if="branch.freeform_cp_schedule")
                  | {{branch.freeform_cp_schedule | lineBreaksToCommas}}
                span(v-else)
                  | {{branch.cp_schedule | cpSchedule}}
                | ).
              p(v-else)
                | Получите и верните изделия с посыльным
                | {{renter.short_commercial_name_cases[1]}} в назначенное вами время в оговоренном месте.
                |
                span(v-if='branch.is_delivery_optional')
                  | При самостоятельной доставке – в определенные вами дни в пункте выдачи
                  | ({{branch.cp_address}}, {{branch.cp_off_days | cpWorkdaysFromOffDays}}
                  |
                  span(v-if="branch.freeform_cp_schedule")
                    | {{branch.freeform_cp_schedule | lineBreaksToCommas}}
                  span(v-else)
                    | {{branch.cp_schedule | cpSchedule}}
                  | ).
            .prompt
              p(v-if='renter.deposit_requirement === 2')
                | Берется залог со всех клиентов.
                br
                | Сумма рассчитывается индивидуально.
              p(v-else-if='renter.deposit_requirement === 1')
                | Берется залог с частных клиентов.
                br
                | Сумма рассчитывается индивидуально.
              p(v-else)
                | Внесение залога не&nbsp;требуется.
                | Подписывается акт передачи.
      .row
        .question
          h4#who-does-the-setup
            | Кто осуществляет сетап?
          .answer-container
            .answer
              p(v-if='!renter.is_arrangement_offered')
                | Сетап (монтаж и демонтаж) осуществляется привлеченными вами работниками.
              p(v-else)
                | Сетап (монтаж и демонтаж) осуществляется специалистами
                | {{renter.short_commercial_name_cases[1]}}.
                |
                span(v-if='branch.is_arrangement_optional')
                  | При самостоятельном сетапе&nbsp;– привлеченными вами работниками.
            .prompt
              p
                span(v-if='!branch.is_arrangement_mandatory')
                  | Возврат изделий&nbsp;–
                  | в оригинальной упаковке.
                br
                | За потерю упаковки
                | берем компенсации.
      .row
        .question
          h4#can-i-cancel
            | Можно ли отменить заказ?
          .answer-container
            .answer
              p
                | Да, отменить заказ можно. При отмене заказа за {{renter.freeze_duration + 1 | decline(['день', 'дня', 'дней'])}} до получения или раньше, мы возвращаем полную сумму предоплаты.
                | При отмене
                |
                span(v-if="renter.freeze_duration === 0")
                  | в день
                span(v-else)
                  | за {{renter.freeze_duration | decline(['день', 'дня', 'дней'])}} до
                |
                | получения или позже, удерживаем полную стоимость.
            .prompt
      .row
        .question
          h4#will-i-get-a-compensation
            | Предусмотрены ли компенсации?
          .answer-container
            .answer
              p
                | Да, предусмотрены: компенсации за задержку возврата, повреждение и потерю изделий.
              ul
                li
                  | Компенсация за задержку возврата — {{renter.compensation_for_delay * 100}}% от стоимости аренды задержанных изделий за каждый день просрочки.
                li
                  | Компенсация за повреждение — по оценке ремонтника. В среднем — 1–2 стоимости аренды. Если изделие после повреждения отремонтировать нельзя, мы выставляем компенсацию за потерю.
                li
                  | Компенсация за потерю указана для каждого изделия отдельно в
                  |
                  router-link(:to="getLossPricesUrl(renter.id)" target="_blank")
                    | прейскуранте
                  | . В среднем — {{roundedLossPriceToPriceRatio | decline(['стоимость', 'стоимости', 'стоимостей'])}} аренды.
              p
                | Если мы привезем вам бракованные изделия или ошибемся в сборке, мы тоже компенсируем ущерб.
              ul
                li
                  | Компенсация за брак — {{renter.compensation_for_defects * 100}}% стоимости аренды бракованных изделий.
                li
                  | Компенсация за недокомплект — {{renter.compensation_for_shortfall * 100}}% стоимости аренды недокомплектованных изделий.
            .prompt
      .row
        .question
          h4#what-is-the-price-of-rental
            | Сколько стоит аренда?
          .answer-container
            .answer
              p
                | Базовые цены на аренду изделий указаны в
                |
                | каталоге
                | . Оплатите базовую стоимость аренды и распоряжайтесь изделиями
                |
                span(v-if='renter.base_rent_duration > 1')
                  | до&nbsp;{{renter.base_rent_duration}}&nbsp;дней
                span(v-if='renter.base_rent_duration === 1')
                  | 1&nbsp;день
                |
                | (доплата за&nbsp;{{renter.base_rent_duration + 1}}-й и каждый последующий день&nbsp;–
                | {{renter.additional_day_charge * 100}}% от базовой стоимости).
              p(v-if="renter.smallest_rent_price_with_delivery !== 0 || renter.smallest_rent_price_without_delivery !== 0")
                | Минимальный заказ:
                |
                span(v-if="renter.smallest_rent_price_with_delivery === renter.smallest_rent_price_without_delivery")
                  | {{renter.smallest_rent_price_without_delivery | money(renter.country)}}.
                span(v-else)
                  | при самовывозе —
                  |
                  span(v-if="renter.smallest_rent_price_without_delivery")
                    | {{renter.smallest_rent_price_without_delivery | money(renter.country)}}
                  span(v-else)
                    | без ограничения
                  | ;
                  | при нашей доставке —
                  |
                  span(v-if="renter.smallest_rent_price_with_delivery")
                    | {{renter.smallest_rent_price_with_delivery | money(renter.country)}}
                  span(v-else)
                    | без ограничения
                  | .
            .prompt
              p
                span(v-if='renter.rent_discount != 0.0')
                  | Специалистам праздничной сферы – скидка {{renter.rent_discount * 100}}%.
      .row(v-if='renter.is_delivery_offered')
        .question
          h4#what-is-the-price-of-delivery
            | Сколько стоит доставка?
          .answer-container
            .answer
              p
                span(v-if='renter.average_intratown_delivery_price > 0')
                  | Для
                  | {{branch.town.genitive_name}}
                  | стоимость доставки туда и обратно составляет в среднем
                  | {{renter.average_intratown_delivery_price * branch.delivery_price_multiplier | money(renter.country)}}.
                span(v-else)
                  | Доставка в пределах
                  | {{branch.town.genitive_name}}
                  | осуществляется бесплатно.
                |
                | За доставку вне города взимается дополнительная плата (в среднем
                | {{renter.average_extratown_delivery_price_per_km * branch.delivery_price_multiplier | money(renter.country)}}/км).
            .prompt
      .row(v-if='renter.is_arrangement_offered')
        .question
          h4#what-is-the-price-of-setup
            | Сколько стоит сетап?
          .answer-container
            .answer
              p
                span(v-if='renter.average_arrangement_price > 0')
                  | Для
                  | {{branch.town.genitive_name}}
                  | стоимость сетапа (монтажа и демонтажа) составляет в среднем
                  | {{renter.min_arrangement_worker_price * branch.arrangement_price_multiplier * 2 | money(renter.country)}}.
                span(v-else)
                  | Сетап осуществляется бесплатно.
            .prompt
      .row(v-if='renter.is_delivery_offered && !(branch.is_delivery_mandatory && !branch.is_arrangement_optional)')
        .question
          h4#how-do-i-save
            | Как сэкономить?
          .answer-container
            .answer
              p(v-if='branch.is_delivery_optional && renter.is_arrangement_offered')
                | Организуйте доставку и сетап самостоятельно. Чтобы правильно спланировать транспорт,
                | число работников и продолжительность сетапа, опирайтесь на данные об
                | объеме изделий и работ, приведенные в заказе.
              p(v-if='branch.is_delivery_optional && !renter.is_arrangement_offered')
                | Организуйте доставку самостоятельно. Чтобы правильно спланировать транспорт,
                | опирайтесь на данные об объеме изделий, приведенные в заказе.
              p(v-if='branch.is_delivery_mandatory && branch.is_arrangement_optional')
                | Организуйте сетап самостоятельно. Чтобы правильно спланировать число работников
                | и продолжительность сетапа, опирайтесь на данные об объеме работ, приведенные в заказе.
            .prompt
      .row
        .question
          h4#where-do-i-get-help
            | Куда обратиться за помощью?
          .answer-container
            .answer
              p
                | Звоните на клиентскую линию
                | {{renter.short_commercial_name_cases[1]}}:
                | {{branch.customer_service_phone | phoneNumber(renter.country)}}
                | ({{renter.customer_service_off_days | cpWorkdaysFromOffDays}},
                | {{renter.customer_service_schedule | cpSchedule}}).
                | Оператор проконсультирует вас по вопросам ассортимента, размещения заказа, использования и стоимости аренды изделий.
            .prompt
      .row(v-if="renter.rules_addendum")
        .question
          h4#addendum
            | Прочие условия
          .answer-container
            .answer
              p(v-for="paragraph in splitIntoParagraphs(renter.rules_addendum)" v-html="linkify(paragraph)")
            .prompt
      .row(v-if="renter.form_of_incorporation !== 'Самозанятый'")
        .question
          h4#details
            | Реквизиты
          .answer-container
            .answer
              p
                | Получатель:
                | {{renter.full_legal_name}}
              p(v-if="renter.tin")
                | ИНН:
                | {{renter.tin}}
              p(v-if="renter.rrc")
                | КПП:
                | {{renter.rrc}}
              p
                | Счет №:
                | {{renter.bank_account}}
              p
                | Банк получателя:
                | {{renter.bank_name}}
              p
                | БИК:
                | {{renter.bic}}
              p
                | Корреспондентский счет:
                | {{renter.correspondent_account}}
            .prompt
</template>

<script>
import _ from 'lodash'
import allowedPaymentMethodsFor from 'Shared/allowedPaymentMethodsFor'

import IconPdf from 'RenterSite/sprites/pdf.svg'

const PAYMENT_METHOD_TEXT_REPRESENTATIONS = {
  robokassa: 'через Робокассу',
  sberbank: 'переводом на карту',
  wire_transfer: 'по счету банковским переводом',
  cash: 'наличными'
}

const spellOutPaymentMethods = function (methods, textRepresentations) {
  const allowed = _.values(_.pick(textRepresentations, methods))
  if (allowed.length === 1) {
    return allowed[0]
  }
  return allowed.slice(0, -1).join(', ') + ' или ' + _.last(allowed)
}

export default {
  name: "agv-rules",
  props: {
    renter: Object,
    branch: Object,
    getLossPricesUrl: Function
  },
  components: {IconPdf},
  methods: {
    splitIntoParagraphs(text) {
      return text.split(/\n+/)
    },
    linkify(text) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
      })
    }
  },
  computed: {
    roundedLossPriceToPriceRatio() {
      return Math.round(this.renter.mean_loss_price_to_price_ratio)
    },
    categories() {
      return this.$store.state.categories
    },
    listedCategories() {
      if (this.categories.length > 1) {
        return _.map(this.categories.slice(0, this.categories.length - 1), c => c.name.toLowerCase()).join(', ') + ' и ' + this.categories[this.categories.length - 1].name.toLowerCase()
      } else {
        return this.categories[0].name.toLowerCase()
      }
    },
    allowedPaymentMethodsString() {
      return spellOutPaymentMethods(allowedPaymentMethodsFor(this.renter), PAYMENT_METHOD_TEXT_REPRESENTATIONS)
    },
    arrangementAndDeliveryString() {
      if (this.renter) {
        const deliveryQuestion = this.renter.is_delivery_offered
        const arrangementQuestion = this.renter.is_arrangement_offered
        if (deliveryQuestion || arrangementQuestion) {
          const questions = []
          if (deliveryQuestion) {
            questions.push('доставке')
          }
          if (arrangementQuestion) {
            questions.push('сетапе')
          }
          return `, определите потребность в ${questions.join(' и ')}`
        } else {
          return ''
        }
      }
    }
  }
}
</script>

<style scoped>

.table-of-contents {
  width: 300px;
  padding-left: 50px;
}

.table-of-contents li {
  list-style: none;
  margin-bottom: 16px;
}

.questions {
  width: 80%;
}

.question {
  line-height: 20px;
  margin-bottom: 26px;
}

.question h4 {
  font-size: 17px;
  line-height: 19px;
  font-weight: normal;
  display: block;
  padding-bottom: 12px;
}

.question p {
  display: block;
  font-size: 14px;
  padding-bottom: 15px;
}

.question ul {
  padding-bottom: 15px;
  display: block;
}

.question li {
  list-style-type: disc;
  margin-left: 62px;
  font-size: 14px;
  margin-bottom: 10px;
}

.question a {
  color: #4a90e2;
}

.answer-container {
  display: flex;
}

.answer {
  flex-grow: 1;
}

.prompt {
  min-width: 250px;
  width: 250px;
  padding-left: 38px;
  padding-right: 35px;
}

.prompt p {
  padding-left: 4px;
  font-size: 13px;
  padding-bottom: 0;
  color: #9b9b9b;
}

.icon-pdf {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.rules {
  font-size: 13px;
  line-height: 15px;
}

.agv-rules {
  display: flex;
  flex-direction: row-reverse;
}
</style>
